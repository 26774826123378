/* mr-dafoe-regular - latin */
@font-face {
  font-family: 'Mr Dafoe';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/mr-dafoe-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/mr-dafoe-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./fonts/mr-dafoe-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('./fonts/mr-dafoe-v14-latin-regular.woff') format('woff'), /* Modern Browsers */ url('./fonts/mr-dafoe-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('./fonts/mr-dafoe-v14-latin-regular.svg#MrDafoe') format('svg'); /* Legacy iOS */
}


/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/raleway-v27-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/raleway-v27-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./fonts/raleway-v27-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('./fonts/raleway-v27-latin-regular.woff') format('woff'), /* Modern Browsers */ url('./fonts/raleway-v27-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('./fonts/raleway-v27-latin-regular.svg#Raleway') format('svg'); /* Legacy iOS */
}

/* raleway-600 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/raleway-v27-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/raleway-v27-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./fonts/raleway-v27-latin-600.woff2') format('woff2'), /* Super Modern Browsers */ url('./fonts/raleway-v27-latin-600.woff') format('woff'), /* Modern Browsers */ url('./fonts/raleway-v27-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */ url('./fonts/raleway-v27-latin-600.svg#Raleway') format('svg'); /* Legacy iOS */
}

/* raleway-700 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/raleway-v27-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/raleway-v27-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./fonts/raleway-v27-latin-700.woff2') format('woff2'), /* Super Modern Browsers */ url('./fonts/raleway-v27-latin-700.woff') format('woff'), /* Modern Browsers */ url('./fonts/raleway-v27-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('./fonts/raleway-v27-latin-700.svg#Raleway') format('svg'); /* Legacy iOS */
}
