@import "./cookieconsent.css";
@import "./leaflet.css";
@import "./leaflet-cluster.css";
@import "./fonts.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a:hover, button:hover {
    @apply cursor-pointer;
  }

  input:focus {
    outline: none !important;

  }

  textarea:focus {
    outline: none !important;
  }

  select {
    -webkit-appearance: none;
  }

  .label-helper {
    transition: 0.2s bottom, 0.2s;
    z-index: 10;
    @apply py-5 text-sm left-0 -bottom-1 text-gray-300;
  }

  .input-text:focus + .label-helper,
  .input-text:invalid + .label-helper {
    bottom: 60%;
    @apply p-0 text-xs text-primary;
  }

  /* Price Range */
  input[type="range"]::-webkit-slider-thumb {
    pointer-events: auto;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    -webkit-appearance: none;
    @apply bg-action;

    /* @apply w-6 h-6 appearance-none pointer-events-auto; */
  }

  .h-underline::after {
    height: 0.125rem;
    width: 3.5rem;
    content: '';
    background-color: rgb(251, 185, 0);
    margin-left: 1.25rem;
    display: block;
  }

  .h-underline.no-offset::after {
    margin-left: 0;
  }

  .alert {
    @apply px-3 py-2 rounded my-2 block;
  }

  .alert.alert-success {
    @apply bg-action-50;
  }

  .alert.alert-info {
    @apply bg-primary-50;
  }

}

@layer utilities {

  #fireworks {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10000 !important;
  }
}

.image-gallery.fullscreen-modal {
  z-index: 100 !important;
}

.modalFull {
  width: 90% !important;
  max-width: 90% !important;
}

.h-screen {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.cms-offer-text strong {
  @apply text-2xl font-bold
}

#cm.bar #c-inr {
  @apply md:flex md:max-w-7xl md:mx-auto md:w-full md:items-center;
}

#cc_div #cm {
  @apply p-3;
}

#cm.bar #c-inr-i {
  @apply pr-4;
}

#cm.bar #c-bns {
  @apply w-full md:mt-0;
}

.react-switch-handle {
  animation: pulsate 1s 4 ease-out, pulse-side 1s 3 ease-out;
}

@-webkit-keyframes pulsate {
  0% {
    box-shadow: 0 0 0 0 rgba(5, 55, 109, .5);
  }
  50% {
    box-shadow: 0 0 4px 6px rgba(5, 55, 109, .5);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(5, 55, 109, .5);
  }
}

@-webkit-keyframes pulse-side {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0px);
  }
}

#header-image{
  background-image:
    linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 55%, rgba(2,2,33,1) 90%),
    url('/images/chartercheck-background-imge.webp');
  width: 100%;
  background-size: cover;

  .shadow-box, &.shadow-box {
    box-shadow: inset 0 0 0 500px #080511bb;
  }
}

.customSlider .swiper-button-next, .customSlider .swiper-button-prev
{
  width: 51px;
  height: 57px;
  top:48%;
  background: rgb(220 220 220 /.75);
  border-radius: 0.5rem;
}


#marina-map {
  width: 100%;
  background: #222222;

  .leaflet-div-icon {
    background: transparent;
    border: 0;
  }

  .adac-marina {
    background: #F8CD46;
    color: #000;
    line-height: 30px;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    font-weight: bold;
    text-align: center;
  }

  .leaflet-tile {
    border: 0;
  }

}

@media (max-width: 1024px) {
  #text-early-booking:before{
    width: 45px;
    height: 45px;
    background-size: 45px 45px;
    top: -20px;
    left: -20px;
  }
}

.input-wrapper.with-errors svg {
  fill: rgb(220, 38, 38);
}

.input-wrapper.with-errors input {
  border: 2px solid rgb(220, 38, 38);
}

.error-label {
  bottom: -24px;
  left: 0px;
  z-index: 100;
  background-color: rgb(220, 38, 38);
}

.error-label:before {
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid rgb(220, 38, 38);
  position: absolute;
  top: -5px;
  left: 13px;
}

#date-range-picker-id {
  cursor: pointer;
}

.mandatory:after {
  content: '*';
  color: red;
}

#adac-tooltip {
  opacity: 1;
  background-color: theme('colors.adac.bg');
  color: theme('colors.adac.fg');
  text-align: center;
  max-width: 200px;
  padding: .25em .5em;
}
